#error-page-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 6rem;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  svg {
    &.warning {
      margin-bottom: 24px;
      width: 50px;
      height: auto;
      fill: var(--color-critical);
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 24px;
      span {
        margin-left: 8px;
      }
    }
  }

  span {
    text-align: center;
  }

  @media screen and (min-width: 1050px) {
    padding: 7rem 10rem 8rem 8rem;
  }

  // @media screen and (max-width: 850px) {
  //   height: calc(100vh - 65px);
  // }

  // @media screen and (min-width: 700px) {
  //   height: calc(100vh - 65px);
  // }
}
