#signup-screen {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 6rem;
  height: 100vh;
  max-height: 100vh;

  .welcome-card {
    margin-bottom: 66px;
  }

  .signup-procedure {
    .or-divider {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 24px 0;

      .or-text {
        color: var(--color-grey-4);
        margin: 0 8px;
      }

      .dividers {
        width: 100%;
        border: solid 1px var(--color-grey-4);
        background-color: var(--color-grey-4);
      }
    }

    .btn {
      margin: 8px 0;

      span {
        margin-left: 16px;
      }

      svg {
        width: 20px;
        height: 20px;
      }

      &.email {
        svg {
          fill: var(--color-grey-6);
        }
      }
    }
  }

  .login-now {
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    margin-top: auto;
    height: max-content;

    a {
      margin-left: 8px;
      color: var(--color-primary);
      text-decoration: none;
    }
  }

  @media screen and (min-width: 1050px) {
    padding: 7rem 10rem 8rem 8rem;
  }

  @media screen and (min-width: 700px) {
    h3 {
      font-size: 32px;
    }

    .signup-procedure {
      .signup-btns {
        display: flex;
        justify-content: space-between;

        .btn {
          max-width: 48%;
        }
      }

      .login-now {
        max-width: 520px;
      }
    }
  }
}
