#emailForm-component {
  .form-checkbox {
    margin: 30px 0 44px 0;
  }

  button {
    span {
      margin-right: 16px;
    }
  }

  .MuiInputBase-root:not(:hover) {
    background-color: var(--color-paper);
  }

  @media screen and (min-width: 700px) {
    .MuiInputBase-root {
      height: 46px;
    }
  }
}
