#navbar {
  width: 100%;
  height: auto;
  background-color: var(--color-paper);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  span,
  p {
    color: var(--color-grey-6);
  }

  svg {
    fill: var(--color-grey-6);
  }

  .logo-and-gastronaut {
    display: flex;
    align-items: center;

    img {
      height: auto;
      width: 100%;
      max-height: 32px;
      max-width: 32px;
      margin-right: 8px;
    }
  }

  .darkmode-and-lang {
    display: flex;
    align-items: center;
    width: max-content;
  }

  @media screen and (min-width: 700px) {
    max-height: 80px;

    .logo-and-gastronaut {
      display: flex;
      align-items: center;

      img {
        max-height: 40px;
        max-width: 40px;
      }
    }
  }
}
