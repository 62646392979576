#pending-invit-screen {
  align-items: center;
  justify-content: stretch;
  padding: 2rem;
  padding-top: 6rem;
  width: 100%;
  height: 100vh;

  svg {
    margin-top: 34px;
  }

  .pending-card {
    .card-text {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .or-divider {
    margin: 48px 0 24px 0;
  }

  .download-badges {
    max-width: 286px;
    margin-top: 48px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1050px) {
    padding: 7rem 10rem 8rem 8rem;

    .download-badges {
      margin-top: 56px;
      max-width: 400px;
    }
  }
}
