#send-request-screen {
  padding: 2rem;
  padding-top: 6rem;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  .textfield {
    margin-top: 36px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  button {
    margin-top: 16px;

    svg {
      margin-left: 16px;
    }
  }

  .MuiInputBase-root:not(:hover) {
    background-color: var(--color-paper);
  }

  @media screen and (min-width: 1050px) {
    padding: 7rem 10rem 8rem 8rem;
  }

  // @media screen and (max-width: 850px) {
  //   height: calc(100vh - 65px);
  // }

  @media screen and (min-width: 700px) {
    // height: calc(100vh - 65px);

    .MuiInputBase-root {
      height: 46px;
    }
  }
}
