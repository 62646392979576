.formcontrol-showpwd {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .MuiInputBase-root {
    background-color: var(--color-bg);
    border-radius: 4px 4px 0 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.13);
    }
  }

  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(12px, 20px) scale(1);
    pointer-events: none;

    &.MuiInputLabel-marginDense {
      transform: translate(12px, 17px) scale(1);
    }
  }

  .MuiInput-underline {
    &:hover:not(.Mui-disabled):before {
      border-bottom: black 1px solid;
    }
  }

  .MuiFilledInput-root {
    background-color: var(--color-bg);

    &.Mui-focused {
      background-color: var(--color-bg);
    }

    &.Mui-hovered {
      background-color: var(--color-secondary);
    }
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }

  input {
    z-index: 10;
  }

  .textfield {
    padding: 20px 12px;
    max-height: 47px;
  }

  @media screen and (min-width: 700px) {
    .textfield {
      max-height: 47px;
    }
  }
}
